export default [
  {
    id: '0',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/0563c3df12637178e8b08858e5fd11e4a6906acc.jpg@320w_200h.webp',
    videoTitle: '世界上广告最多的网站判定！真的有10000条广告！【暗物质#2】',
    author: '牧草君1',
    authorIconSrc: 'https:////i1.hdslb.com/bfs/face/bb856d0bed8ef8abc0544fd5bd86da4cedf62a04.jpg@60w_60h_1c.png',
    playCount: '41.4万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '1',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/b08463bc1257b6294bad1e1646a3203f9f3a0c60.jpg@320w_200h.webp',
    videoTitle: '【圣地亚哥金曲】藏，超好听的中国风电音鬼畜！',
    author: '牧草君2',
    authorIconSrc: 'https://i0.hdslb.com/bfs/face/54923922eea6532c2dff95e45743b6a1203c0230.jpg@60w_60h.webp',
    playCount: '24.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '2',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/02cf0e3a1fd52f80763fd51ee7fae69e51cf173c.jpg@320w_200h.webp',
    videoTitle: '迈克尔杰克逊封神的12秒，无人能做到',
    author: '牧草君3',
    authorIconSrc: 'https:////i1.hdslb.com/bfs/face/bb856d0bed8ef8abc0544fd5bd86da4cedf62a04.jpg@60w_60h_1c.png',
    playCount: '28.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '3',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/dc7147ffa4e11a2fffa84b295b2f2bdbbfe3e6d7.jpg@320w_200h.webp',
    videoTitle: '【2018】年度影视混剪 Ready Story 2018【混剪】',
    author: '牧草君4',
    authorIconSrc: 'https://i0.hdslb.com/bfs/face/54923922eea6532c2dff95e45743b6a1203c0230.jpg@60w_60h.webp',
    playCount: '29.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '4',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/0563c3df12637178e8b08858e5fd11e4a6906acc.jpg@320w_200h.webp',
    videoTitle: '世界上广告最多的网站判定！真的有10000条广告！【暗物质#2】',
    author: '牧草君1',
    authorIconSrc: 'https:////i1.hdslb.com/bfs/face/bb856d0bed8ef8abc0544fd5bd86da4cedf62a04.jpg@60w_60h_1c.png',
    playCount: '41.4万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '5',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/b08463bc1257b6294bad1e1646a3203f9f3a0c60.jpg@320w_200h.webp',
    videoTitle: '【圣地亚哥金曲】藏，超好听的中国风电音鬼畜！',
    author: '牧草君2',
    authorIconSrc: 'https://i0.hdslb.com/bfs/face/54923922eea6532c2dff95e45743b6a1203c0230.jpg@60w_60h.webp',
    playCount: '24.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '6',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/02cf0e3a1fd52f80763fd51ee7fae69e51cf173c.jpg@320w_200h.webp',
    videoTitle: '迈克尔杰克逊封神的12秒，无人能做到',
    author: '牧草君3',
    authorIconSrc: 'https:////i1.hdslb.com/bfs/face/bb856d0bed8ef8abc0544fd5bd86da4cedf62a04.jpg@60w_60h_1c.png',
    playCount: '28.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '7',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/dc7147ffa4e11a2fffa84b295b2f2bdbbfe3e6d7.jpg@320w_200h.webp',
    videoTitle: '【2018】年度影视混剪 Ready Story 2018【混剪】',
    author: '牧草君4',
    authorIconSrc: 'https://i0.hdslb.com/bfs/face/54923922eea6532c2dff95e45743b6a1203c0230.jpg@60w_60h.webp',
    playCount: '29.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '8',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/0563c3df12637178e8b08858e5fd11e4a6906acc.jpg@320w_200h.webp',
    videoTitle: '世界上广告最多的网站判定！真的有10000条广告！【暗物质#2】',
    author: '牧草君1',
    authorIconSrc: 'https:////i1.hdslb.com/bfs/face/bb856d0bed8ef8abc0544fd5bd86da4cedf62a04.jpg@60w_60h_1c.png',
    playCount: '41.4万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '9',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/b08463bc1257b6294bad1e1646a3203f9f3a0c60.jpg@320w_200h.webp',
    videoTitle: '【圣地亚哥金曲】藏，超好听的中国风电音鬼畜！',
    author: '牧草君2',
    authorIconSrc: 'https://i0.hdslb.com/bfs/face/54923922eea6532c2dff95e45743b6a1203c0230.jpg@60w_60h.webp',
    playCount: '24.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '10',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/02cf0e3a1fd52f80763fd51ee7fae69e51cf173c.jpg@320w_200h.webp',
    videoTitle: '迈克尔杰克逊封神的12秒，无人能做到',
    author: '牧草君3',
    authorIconSrc: 'https:////i1.hdslb.com/bfs/face/bb856d0bed8ef8abc0544fd5bd86da4cedf62a04.jpg@60w_60h_1c.png',
    playCount: '28.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '11',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/dc7147ffa4e11a2fffa84b295b2f2bdbbfe3e6d7.jpg@320w_200h.webp',
    videoTitle: '【2018】年度影视混剪 Ready Story 2018【混剪】',
    author: '牧草君4',
    authorIconSrc: 'https://i0.hdslb.com/bfs/face/54923922eea6532c2dff95e45743b6a1203c0230.jpg@60w_60h.webp',
    playCount: '29.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '12',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/0563c3df12637178e8b08858e5fd11e4a6906acc.jpg@320w_200h.webp',
    videoTitle: '世界上广告最多的网站判定！真的有10000条广告！【暗物质#2】',
    author: '牧草君1',
    authorIconSrc: 'https:////i1.hdslb.com/bfs/face/bb856d0bed8ef8abc0544fd5bd86da4cedf62a04.jpg@60w_60h_1c.png',
    playCount: '41.4万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '13',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/b08463bc1257b6294bad1e1646a3203f9f3a0c60.jpg@320w_200h.webp',
    videoTitle: '【圣地亚哥金曲】藏，超好听的中国风电音鬼畜！',
    author: '牧草君2',
    authorIconSrc: 'https://i0.hdslb.com/bfs/face/54923922eea6532c2dff95e45743b6a1203c0230.jpg@60w_60h.webp',
    playCount: '24.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '14',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/02cf0e3a1fd52f80763fd51ee7fae69e51cf173c.jpg@320w_200h.webp',
    videoTitle: '迈克尔杰克逊封神的12秒，无人能做到',
    author: '牧草君3',
    authorIconSrc: 'https:////i1.hdslb.com/bfs/face/bb856d0bed8ef8abc0544fd5bd86da4cedf62a04.jpg@60w_60h_1c.png',
    playCount: '28.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '15',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/dc7147ffa4e11a2fffa84b295b2f2bdbbfe3e6d7.jpg@320w_200h.webp',
    videoTitle: '【2018】年度影视混剪 Ready Story 2018【混剪】',
    author: '牧草君4',
    authorIconSrc: 'https://i0.hdslb.com/bfs/face/54923922eea6532c2dff95e45743b6a1203c0230.jpg@60w_60h.webp',
    playCount: '29.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '16',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/0563c3df12637178e8b08858e5fd11e4a6906acc.jpg@320w_200h.webp',
    videoTitle: '世界上广告最多的网站判定！真的有10000条广告！【暗物质#2】',
    author: '牧草君1',
    authorIconSrc: 'https:////i1.hdslb.com/bfs/face/bb856d0bed8ef8abc0544fd5bd86da4cedf62a04.jpg@60w_60h_1c.png',
    playCount: '41.4万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '17',
    videoSrc: 'https://video-competition.oss-cn-beijing.aliyuncs.com/music.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/b08463bc1257b6294bad1e1646a3203f9f3a0c60.jpg@320w_200h.webp',
    videoTitle: '【圣地亚哥金曲】藏，超好听的中国风电音鬼畜！',
    author: '牧草君2',
    authorIconSrc: 'https://i0.hdslb.com/bfs/face/54923922eea6532c2dff95e45743b6a1203c0230.jpg@60w_60h.webp',
    playCount: '24.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '18',
    videoSrc: 'http://sites.zbztb.cn/medias/20181219211920.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/02cf0e3a1fd52f80763fd51ee7fae69e51cf173c.jpg@320w_200h.webp',
    videoTitle: '迈克尔杰克逊封神的12秒，无人能做到',
    author: '牧草君3',
    authorIconSrc: 'https:////i1.hdslb.com/bfs/face/bb856d0bed8ef8abc0544fd5bd86da4cedf62a04.jpg@60w_60h_1c.png',
    playCount: '28.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '19',
    videoSrc: 'http://sites.zbztb.cn/medias/20181219211910.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/dc7147ffa4e11a2fffa84b295b2f2bdbbfe3e6d7.jpg@320w_200h.webp',
    videoTitle: '【2018】年度影视混剪 Ready Story 2018【混剪】',
    author: '牧草君4',
    authorIconSrc: 'https://i0.hdslb.com/bfs/face/54923922eea6532c2dff95e45743b6a1203c0230.jpg@60w_60h.webp',
    playCount: '29.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '20',
    videoSrc: 'http://sites.zbztb.cn/medias/2018120195458.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/0563c3df12637178e8b08858e5fd11e4a6906acc.jpg@320w_200h.webp',
    videoTitle: '世界上广告最多的网站判定！真的有10000条广告！【暗物质#2】',
    author: '牧草君1',
    authorIconSrc: 'https:////i1.hdslb.com/bfs/face/bb856d0bed8ef8abc0544fd5bd86da4cedf62a04.jpg@60w_60h_1c.png',
    playCount: '41.4万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '21',
    videoSrc: 'http://sites.zbztb.cn/medias/20181219211856.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/b08463bc1257b6294bad1e1646a3203f9f3a0c60.jpg@320w_200h.webp',
    videoTitle: '【圣地亚哥金曲】藏，超好听的中国风电音鬼畜！',
    author: '牧草君2',
    authorIconSrc: 'https://i0.hdslb.com/bfs/face/54923922eea6532c2dff95e45743b6a1203c0230.jpg@60w_60h.webp',
    playCount: '24.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '22',
    videoSrc: 'http://sites.zbztb.cn/medias/20181219211920.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/02cf0e3a1fd52f80763fd51ee7fae69e51cf173c.jpg@320w_200h.webp',
    videoTitle: '迈克尔杰克逊封神的12秒，无人能做到',
    author: '牧草君3',
    authorIconSrc: 'https:////i1.hdslb.com/bfs/face/bb856d0bed8ef8abc0544fd5bd86da4cedf62a04.jpg@60w_60h_1c.png',
    playCount: '28.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  },
  {
    id: '23',
    videoSrc: 'http://sites.zbztb.cn/medias/20181219211910.mp4',
    poster: 'https://i0.hdslb.com/bfs/archive/dc7147ffa4e11a2fffa84b295b2f2bdbbfe3e6d7.jpg@320w_200h.webp',
    videoTitle: '【2018】年度影视混剪 Ready Story 2018【混剪】',
    author: '牧草君4',
    authorIconSrc: 'https://i0.hdslb.com/bfs/face/54923922eea6532c2dff95e45743b6a1203c0230.jpg@60w_60h.webp',
    playCount: '29.9万',
    likeCount: '5.2万',
    favCount: '2.1万',
    commentCount: 1345,
    date: '12-15',
    vin: 'GB/T16735-1997',
    division: '北京',
    tags: '发动机,宝马,噪声,一等奖'
  }
]
