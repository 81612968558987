import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
const routes: Array<RouteRecordRaw> = [
  { path: '/', meta: { hideTabBar: true, title: '全国汽车维修案例大赛' }, component: () => import('@/views/home/index.vue') },
  { path: '/schedule', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/schedule/index.vue') },
  { path: '/vrhall', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/vrhall/index.vue') },
  { path: '/news', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/carsnews/index.vue') },
  { path: '/events', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/carsnews/events.vue') },
  { path: '/player', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/carsnews/player.vue') },
  { path: '/rules', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/carsnews/rules.vue') },
  { path: '/ranking', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/rank/index.vue') },
  { path: '/rank', name: 'rank', meta: { title: '全国汽车维修案例大赛', keepAlive: true }, component: () => import('@/views/rank/newIndex.vue') },
  { path: '/video/:id', meta: { hideTabBar: true, title: '全国汽车维修案例大赛' }, component: () => import('@/views/video/index.vue') },
  { path: '/work_video/:id', meta: { hideTabBar: true, title: '全国汽车维修案例大赛' }, component: () => import('@/views/workvideo/index.vue') },
  { path: '/re_video/:id', meta: { hideTabBar: true }, component: () => import('@/views/revideo/index.vue') },
  { path: '/search', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/workssearch/index.vue') },
  { path: '/login', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/login/index.vue') },
  { path: '/helptips', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/helptips/index.vue') },
  { path: '/pdf', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/pdf/index.vue') },
  { path: '/pdfpreview', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/pdf/pdfpreview.vue') },
  { path: '/works', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/works/index.vue') },
  { path: '/newWorks', name: 'newWorks', meta: { title: '全国汽车维修案例大赛', keepAlive: true }, component: () => import('@/views/works/newIndex.vue') },
  { path: '/file', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/file/index.vue') },
  { path: '/complain', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/complain/index.vue') },
  { path: '/certificate', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/certificate/index.vue') },
  { path: '/playerMessage/:id', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/playerMessage/index.vue') },
  { path: '/seeCertificate/:id', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/certificate/components/see_certificate.vue') },
  { path: '/corporate/:id', meta: { title: '全国汽车维修案例大赛' }, component: () => import('@/views/corporate/index.vue') }

  // { path: '/second', meta: { hideTabBar: true, title: '全国汽车维修案例大赛第二季' }, component: () => import('@/views/home/second_index.vue') },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
      document.title = to.meta.title + ''
  }
  next()
})

export default router
