export default [
  {
    id: '0',
    link: '',
    imgSrc: 'https://i0.hdslb.com/bfs/archive/0563c3df12637178e8b08858e5fd11e4a6906acc.jpg@320w_200h.webp',
    desc: '车灯密封不严如何快速处理',
    playCount: '24.9万',
    commentCount: '1345',
    videoSrc: 'http://175.27.247.87:8081/d3297919a2c3c1e7c031fdd34e99f8a8.mp4',
    tags: '车灯,奥迪,大灯,起雾,安徽',
    award: '一等奖'
  },
  {
    id: '1',
    link: '',
    imgSrc: 'https://i0.hdslb.com/bfs/archive/b08463bc1257b6294bad1e1646a3203f9f3a0c60.jpg@320w_200h.webp',
    desc: '3个步骤校准油位表防止指针总是对不齐',
    playCount: '63.8万',
    commentCount: '7825',
    videoSrc: 'http://175.27.247.87:8081/d3297919a2c3c1e7c031fdd34e99f8a8.mp4',
    tags: '油位表,校准,凯迪拉克,天津',
    award: '一等奖'
  },
  {
    id: '2',
    link: '',
    imgSrc: 'https://i0.hdslb.com/bfs/archive/02cf0e3a1fd52f80763fd51ee7fae69e51cf173c.jpg@320w_200h.webp',
    desc: '帕萨特2.0发动机大修漏机油和冷冻液',
    playCount: '63.8万',
    commentCount: '7825',
    videoSrc: 'http://175.27.247.87:8081/d3297919a2c3c1e7c031fdd34e99f8a8.mp4',
    tags: '发动机,漏油,水箱散热慢,帕萨特,上海',
    award: '二等奖'
  },
  {
    id: '3',
    link: '',
    imgSrc: 'https://i0.hdslb.com/bfs/archive/dc7147ffa4e11a2fffa84b295b2f2bdbbfe3e6d7.jpg@320w_200h.webp',
    desc: '丰田皇冠故障码P0174维修方法',
    playCount: '40.0万',
    commentCount: '1066',
    videoSrc: 'http://175.27.247.87:8081/d3297919a2c3c1e7c031fdd34e99f8a8.mp4',
    tags: '故障码,丰田,氧传感器,广东',
    award: '优秀奖'
  },
  {
    id: '4',
    link: '',
    imgSrc: 'https://i2.hdslb.com/bfs/archive/2cc604557cab1f6fd79591981891467f7b825010.jpg@320w_200h.webp',
    desc: 'EcoBoost发动机缺缸故障排查秘诀',
    playCount: '82.7万',
    commentCount: '719',
    videoSrc: 'http://175.27.247.87:8081/d3297919a2c3c1e7c031fdd34e99f8a8.mp4',
    tags: '福特,发动机,火花塞,山东',
    award: '优秀奖'
  },
  {
    id: '5',
    link: '',
    imgSrc: 'https://i1.hdslb.com/bfs/archive/0fc171eaa7bf6b81cf5427fc57db104a4ef719d7.jpg@320w_200h.webp',
    desc: '使用5年的轮胎怎么检查',
    playCount: '28.9万',
    commentCount: '817',
    videoSrc: 'http://175.27.247.87:8081/d3297919a2c3c1e7c031fdd34e99f8a8.mp4',
    tags: '换胎,裂纹检查,米其林,甘肃',
    award: '优秀奖'
  }
]
