export default [
    {
      num: '1',
      name: '比亚迪',
      worksnum: 2323,
      viewnum: 12123
    },
    {
      num: '2',
      name: '比亚迪',
      worksnum: 2323,
      viewnum: 12123
    },
    {
      num: '3',
      name: '比亚迪',
      worksnum: 2323,
      viewnum: 12123
    },
]