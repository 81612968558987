export default [
    {
        id: '0',
        des: '大赛规则',
        top: [
            {
                id: '0',
                des: '2022/10/31 初赛作品的评分规则通知'
            },
            {
                id: '1',
                des: '2022/10/31 初赛作品的评分规则通知'
            },
            {
                id: '2',
                des: '2022/10/31 初赛作品的评分规则通知'
            },
        ]
    },
    {
        id: '1',
        des: '赛事动态',
        top: [
            {
                id: '0',
                des: '2022/10/31 初赛作品的评分规则通知'
            },
            {
                id: '1',
                des: '2022/10/31 初赛作品的评分规则通知'
            },
            {
                id: '2',
                des: '2022/10/31 初赛作品的评分规则通知'
            },
        ]
    },
    {
        id: '2',
        des: '选手风采',
        top: [
            {
                id: '0',
                des: '2022/10/31 初赛作品的评分规则通知'
            },
            {
                id: '1',
                des: '2022/10/31 初赛作品的评分规则通知'
            },
            {
                id: '2',
                des: '2022/10/31 初赛作品的评分规则通知'
            },
        ]
    },
]