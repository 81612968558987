import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// @相当于src
import '@/assets/styles/base.less'
import '@/assets/styles/iconfont.less'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 需要用到vant组件里面的tab标签页，在入口处引入(其他的同理)
import { Button, CellGroup, Col, Divider, DropdownItem, DropdownMenu, Field, Grid, GridItem, List, Picker, Popup, PullRefresh, Row, Search, Swipe, SwipeItem, Tab, Tabs } from 'vant'
// import VueChatBot from 'vue-simple-chatbot'
// import VueChatBot from 'vue-simple-chatbot'
// 让mock接口跑起来
import '@/mock/index'
import axios from 'axios'
// import { baseUrl } from '@/assets/js/common.js'
// console.log(baseUrl)
// axios.defaults.baseURL = 'https://mock.apifox.cn'
console.log(process.env, '2222222222')
axios.defaults.baseURL = process.env.VUE_APP_API_URL

// 此处添加一个变量app，方便按照文档的方式引入
const pinia = createPinia()
pinia.use(piniaPersist)
const app = createApp(App)
app.use(Tab)
app.use(Tabs)
app.use(Button)
app.use(Swipe)
app.use(SwipeItem)
app.use(router)
app.use(Search)
app.use(Field)
app.use(CellGroup)
app.use(Col)
app.use(Row)
app.use(Grid)
app.use(GridItem)
app.use(pinia)
app.use(ElementPlus)
// app.use(VueChatBot)
app.use(DropdownMenu)
app.use(DropdownItem)
app.use(Popup)
app.use(Picker)
app.use(List)
app.use(PullRefresh)
app.use(Divider)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.mount('#app')