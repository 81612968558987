export default [
    {
        id: '0',
        division: '安徽赛区',
        content: [
            {
                id: '0',
                des: 'top1: 胎压不稳定的解决妙招'
            },
            {
                id: '1',
                des: 'top2: 胎压不稳定的解决妙招'
            },
            {
                id: '2',
                des: 'top3: 胎压不稳定的解决妙招'
            },
        ]
    },
    {
        id: '1',
        division: '北京赛区',
        content: [
            {
                id: '0',
                des: 'top1: 胎压不稳定的解决妙招'
            },
            {
                id: '1',
                des: 'top2: 胎压不稳定的解决妙招'
            },
            {
                id: '2',
                des: 'top3: 胎压不稳定的解决妙招'
            },
        ]
    },
    {
        id: '2',
        division: '广东赛区',
        content: [
            {
                id: '0',
                des: 'top1: 胎压不稳定的解决妙招'
            },
            {
                id: '1',
                des: 'top2: 胎压不稳定的解决妙招'
            },
            {
                id: '2',
                des: 'top3: 胎压不稳定的解决妙招'
            },
        ]
    },
]