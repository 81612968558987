import Mock from 'mockjs'
import navList from './data/navList'
import swiperList from './data/swiperList'
import videosList from './data/videosList'
import recommendList from './data/recommendList'
import commentsList from './data/commentsList'
import videoDetailList from './data/videoDetailList'
import districtList from './data/districtList'
import brandList from './data/brandList'
import peopleList from './data/peopleList'
import companyList from './data/companyList'
import newsList from './data/newsList'

// mock的配置
Mock.setup({
  // 随机延时50-1000毫秒
  timeout: '50-1000'
})

// 轮播图
Mock.mock(/\/swiperList/, 'get', () => {
  return {
    code: 0,
    result: swiperList
  }
})

// 频道菜单
Mock.mock(/\/navList/, 'get', () => {
  return {
    code: 0,
    result: navList
  }
})

Mock.mock(/\/videosList/, 'get', () => {
  return {
    code: 0,
    result: videosList
  }
})

Mock.mock(/\/recommendList/, 'get', () => {
  return {
    code: 0,
    result: recommendList
  }
})

Mock.mock(/\/commentsList/, 'get', () => {
  return {
    code: 0,
    result: {
      count: 454,
      items: commentsList
    }
  }
})
// 赛区5强榜
Mock.mock(/\/districtList/, 'get', () => {
  return {
    code: 0,
    result: districtList
  }
})
// 品牌榜
Mock.mock(/\/brandList/, 'get', () => {
  return {
    code: 0,
    result: brandList
  }
})
// 人气榜
Mock.mock(/\/peopleList/, 'get', () => {
  return {
    code: 0,
    result: peopleList
  }
})
// 参赛单位榜
Mock.mock(/\/companyList/, 'get', () => {
  return {
    code: 0,
    result: companyList
  }
})
// 新闻
Mock.mock(/\/newsList/, 'get', () => {
  return {
    code: 0,
    result: newsList
  }
})

interface IMockConfig {
  body: string | null;
  type: string
  url: string
}
Mock.mock(/\/videoDetail/, 'get', ({ url }: IMockConfig) => {
  const querystr = url.split('?')[1]
  const queryObj = new URLSearchParams(querystr)
  const id = queryObj.get('id')
  return Mock.mock({
    code: 0,
    result: function () {
      return videoDetailList.find(item => item.id === id)
    }
  })
})
